<template>
  <v-carousel
    class="plans-slider"
    hide-delimiter-background
    show-arrows-on-hover
    hide-delimiters
    max="2"
    :height="520 + benefitsLength * 42"
  >
    <template v-slot:prev="{ on, attrs }">
      <div class="direction-wrapper">
        <v-icon
          class="direction-icon"
          color="secondary"
          v-bind="attrs"
          v-on="on"
          >mdi-chevron-left</v-icon
        >
      </div>
    </template>
    <template v-slot:next="{ on, attrs }">
      <div class="direction-wrapper">
        <v-icon
          class="direction-icon"
          color="secondary"
          v-bind="attrs"
          v-on="on"
          >mdi-chevron-right</v-icon
        >
      </div>
    </template>
    <v-carousel-item
      class="plans-slider-content transparent"
      height="100%"
      v-for="(p, i) in Math.ceil(value.length / numberOfPlansPerPage)"
      :key="i"
    >
      <v-row class="plans-items-container">
        <v-col
          class="col"
          :cols="colsNumber"
          v-for="plan in value.slice(
            i * numberOfPlansPerPage,
            i * numberOfPlansPerPage + numberOfPlansPerPage
          )"
          :key="plan.id"
        >
          <ServicePlanItem
            :benefitsLength="benefitsLength"
            :recommended="plan.is_recommended"
            :value="plan"
            :weeklyDeliverables="weeklyDeliverables"
            :monthlyDeliverables="monthlyDeliverables"
            :assignmentDeliverables="assignmentDeliverables"
            class="ml-2 mr-2"
            @onDeliverableClick="
              (deliverable) => $emit('onDeliverableClick', deliverable)
            "
          ></ServicePlanItem>
        </v-col>
      </v-row>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import ServicePlanItem from "./ServicePlanItem.vue";

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    lgWidth: 1680,
    mdWidth: 1264,
    xsWidth: 864,
  }),
  computed: {
    colsNumber() {
      switch (this.numberOfPlansPerPage) {
        case 4:
          return 3;
        case 3:
          return 4;
        case 2:
          return 6;
        case 1:
          return 12;

        default:
          return 12;
      }
    },
    numberOfPlansPerPage() {
      if (window.innerWidth > this.lgWidth) return 1;
      if (window.innerWidth > this.mdWidth && window.innerWidth <= this.lgWidth)
        return 1;
      if (
        window.innerWidth <= this.mdWidth &&
        window.innerWidth >= this.xsWidth
      )
        return 1;
      else return 1;
    },
    benefitsLength() {
      return this.value && this.value.length
        ? Math.max(
            ...this.value.map((p) => {
              return p.packageItems && p.packageItems.length
                ? p.packageItems.length
                : 0;
            })
          )
        : 2;
    },
    weeklyDeliverables() {
      return this.getDeliverablesByType("WEEKLY");
    },
    monthlyDeliverables() {
      return this.getDeliverablesByType("MONTHLY");
    },
    //returns array fo deliverables to be shown in the right order
    assignmentDeliverables() {
      return this.getDeliverablesByType("DEFAULT");
    },
  },
  components: {
    ServicePlanItem,
  },
  methods: {
    getDeliverablesByType(type) {
      return this.value.reduce((acc, plan) => {
        plan.packageItems.forEach((item) => {
          const duplicate = acc.find((it) => it.id === item.deliverable.id);
          if (item.allocation_type === type && !duplicate)
            acc.push(item.deliverable);
        });
        return acc;
      }, []);
    },
  },
};
</script>



<style lang="scss" scoped>
.plans-slider-bg {
  .plans-slider {
    flex-direction: column;
    display: flex;
    justify-items: center;
    justify-content: center;

    .plans-slider-card {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .plans-slider-subheader {
        margin-bottom: 20px;
      }

      .plans-slider-content {
        align-items: center;
        display: flex;
        flex-direction: column;
      }
    }
  }

  .step-content {
    text-align: center;
  }
}
</style>
