import { render, staticRenderFns } from "./ServiceSectionPlans.vue?vue&type=template&id=6aea04d0&scoped=true&"
import script from "./ServiceSectionPlans.vue?vue&type=script&lang=js&"
export * from "./ServiceSectionPlans.vue?vue&type=script&lang=js&"
import style0 from "./ServiceSectionPlans.vue?vue&type=style&index=0&id=6aea04d0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6aea04d0",
  null
  
)

export default component.exports