<template>
  <v-card
    class="mt-5 pl-0 pr-0 elevation-0 service-sections"
    color="transparent"
  >
    <v-row>
      <v-col>
        <FormSection
          underline
          :label="'Pricing'"
          :icon="!!actions.length ? 'mdi-cog-outline' : ''"
          :actions="actions"
          :editable="!!actions.length"
          :payload="payload"
        ></FormSection>
      </v-col>
    </v-row>
    <v-card-text>
      <v-row>
        <v-col lg="12" class="plans-col" md="12" xs="12" sm="12">
          <ServicePlansCarouselVue
            class="service-details-plans"
            :value="value"
            @onDeliverableClick="
              (deliverable) => $emit('onDeliverableClick', deliverable)
            "
          ></ServicePlansCarouselVue>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>


<script>
import FormSection from "../../../atoms/common/FormSection.vue";
import ServicePlansCarouselVue from "../../../molecules/services/sections/plans/ServicePlansCarousel.vue";
import { SERVICE_BUTTONS } from "../../../../../views/src/apps/service-management/constants/actions.gh";
import { PermissionsHelper } from "../../../../helpers/permissions.helper";

export default {
  props: {
    color: {
      type: String,
      default: "white",
    },
    direction: {
      type: String,
      default: "left",
    },
    value: {
      type: Array,
      default: () => [],
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
    payload: {
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  computed: {
    actions: function () {
      return PermissionsHelper.getActions(
        [SERVICE_BUTTONS.PLANS_EDITOR, SERVICE_BUTTONS.PLAN_CREATE_NEW],
        this.permissions
      );
    },
  },
  methods: {
    onCreate() {},
  },
  components: {
    ServicePlansCarouselVue,
    FormSection,
  },
};
</script>


<style lang="scss" scoped>
.plans-col {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  flex-grow: 1;
  max-width: 100%;
}
</style>