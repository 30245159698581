<template>
  <v-card
    class="free-sections service-sections elevation-0 pa-0 ma-0"
    color="transparent"
  >
    <v-row>
      <v-col>
        <FormSection
          underline
          :label="value.name"
          :right="direction === 'left' ? false : true"
          :icon="!!actions.length ? 'mdi-cog-outline' : ''"
          :actions="actions"
          :editable="!!actions.length"
          :payload="payload"
        ></FormSection>
      </v-col>
    </v-row>
    <v-row v-if="value.content">
      <v-col lg="12" md="12" xs="12" sm="12">
        <div class="text-3-columns">
          <p class="simple-text" v-html="value.content"></p>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="12" class="pa-0 ma-0">
        <transition name="component-fade" mode="out-in">
          <component
            v-bind:is="
              value.steps_type === 'TABS'
                ? 'ServiceFreeSectionTabsVue'
                : value.steps_type === 'SLIDER'
                ? 'ServiceFreeSectionSliderVue'
                : value.steps_type === 'ICONS'
                ? 'ServiceFreeSectionIcons'
                : ''
            "
            :steps="value.steps"
            :autoSpin="value.steps_auto_spin"
            :service="service"
          ></component>
        </transition>
      </v-col>
    </v-row>
  </v-card>
</template>


<script>
import FormSection from "../../../atoms/common/FormSection.vue";
import ServiceFreeSectionSliderVue from "../../../molecules/services/sections/freeSections/ServiceFreeSectionSlider.vue";
import ServiceFreeSectionTabsVue from "../../../molecules/services/sections/freeSections/ServiceFreeSectionTabs.vue";
import ServiceFreeSectionIcons from "../../../molecules/services/sections/freeSections/ServiceFreeSectionIcons.vue";
import { SERVICE_BUTTONS } from "../../../../../views/src/apps/service-management/constants/actions.gh";
import { PermissionsHelper } from "../../../../helpers/permissions.helper";

export default {
  props: {
    value: { default: () => ({}) },

    service: {
      default: () => ({}),
    },
    direction: {
      type: String,
      default: "left",
    },
    color: {
      type: String,
      default: "white",
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
    payload: {
      default: () => ({}),
    },
  },
  data() {
    return {
      tab: null,
    };
  },
  computed: {
    actions: function () {
      return PermissionsHelper.getActions(
        [
          SERVICE_BUTTONS.FREE_SECTION_EDITOR,
          SERVICE_BUTTONS.FREE_SECTION_CREATE_NEW,
          SERVICE_BUTTONS.FREE_SECTION_REMOVE,
        ],
        this.permissions
      );
    },
  },
  components: {
    ServiceFreeSectionTabsVue,
    ServiceFreeSectionSliderVue,
    ServiceFreeSectionIcons,
    FormSection,
  },
};
</script>

   

<style lang="scss" scoped>
.free-sections {
}
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>