var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"how-it-works-slider-bg",style:({
    backgroundImage: `url(${
      _vm.service && _vm.service.type && _vm.service.type.image
        ? _vm.service.type.image
        : '/static/images/backgrounds/69.webp'
    })`,
  })},[_c('v-carousel',{staticClass:"how-it-works-slider dark-alpha-04",attrs:{"hide-delimiter-background":"","show-arrows-on-hover":"","cycle":_vm.autoSpin,"height":"450"},scopedSlots:_vm._u([{key:"prev",fn:function({ on, attrs }){return [_c('div',{staticClass:"direction-wrapper-light"},[_c('v-icon',_vm._g(_vm._b({staticClass:"direction-icon",attrs:{"color":"black"}},'v-icon',attrs,false),on),[_vm._v("mdi-chevron-left")])],1)]}},{key:"next",fn:function({ on, attrs }){return [_c('div',{staticClass:"direction-wrapper-light"},[_c('v-icon',_vm._g(_vm._b({staticClass:"direction-icon",attrs:{"color":"black"}},'v-icon',attrs,false),on),[_vm._v("mdi-chevron-right")])],1)]}}])},_vm._l((_vm.steps),function(step,i){return _c('v-carousel-item',{key:i,attrs:{"height":"100%"}},[_c('v-sheet',{staticClass:"transparent",attrs:{"height":"100%"}},[_c('v-card',{staticClass:"how-it-works-slider-card transparent",attrs:{"dark":"","flat":""}},[_c('v-card-text',{staticClass:"how-it-works-slider-content"},[_c('v-col',{staticClass:"how-it-works-slider-content-slider",attrs:{"cols":"12","lg":"8","sm":"12","xs":"12"}},[(step.icon)?_c('v-icon',{staticClass:"pa-6",attrs:{"size":"40"}},[_vm._v(" "+_vm._s(step.icon)+" ")]):_vm._e(),_c('h1',{staticClass:"how-it-works-tabs-header center"},[_vm._v(" "+_vm._s(step.header)+" ")]),(step.sub_header)?_c('h3',{staticClass:"how-it-works-tabs-subheader mt-5 mb-5 center"},[_vm._v(" "+_vm._s(step.sub_header)+" ")]):_vm._e(),_c('v-divider',{staticClass:"mt-5 mb-5",attrs:{"width":"100%"}}),_c('p',{staticClass:"step-content simple-text"},[_vm._v(" "+_vm._s(step.content)+" ")])],1)],1)],1)],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }