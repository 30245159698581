<template>
  <v-card
    class="elevation-0 service-sections text--white service-section-overview"
    color="transparent"
  >
    <v-row>
      <v-col class="service-section-column">
        <FormSection
          left
          underline
          :label="'overview'"
          :icon="!!actions.length ? 'mdi-cog-outline' : ''"
          :actions="actions"
          :editable="!!actions.length"
          @action="(action) => $emit('action', action)"
        ></FormSection>
      </v-col>
    </v-row>
    <v-card-text>
      <v-row class="">
        <v-col cols="12" lg="8" md="8" sm="6" class="pa-0 overview-text-col">
          <div class="text-2-columns">
            <p class="simple-text" v-html="value.overview"></p>
          </div>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="6" class="pa-0 overview-metrics-col">
          <ServiceFitRateVue
            dark
            class="ma-0 pa-0"
            v-model="value"
          ></ServiceFitRateVue>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>


<script>
import { SERVICE_BUTTONS } from "../../../../../views/src/apps/service-management/constants/actions.gh";
import { PermissionsHelper } from "../../../../helpers/permissions.helper";
import FormSection from "../../../atoms/common/FormSection.vue";
import ServiceFitRateVue from "../../../atoms/service/ServiceFitRate.vue";

export default {
  props: {
    value: {
      default: () => ({}),
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  computed: {
    actions: function () {
      return PermissionsHelper.getActions(
        SERVICE_BUTTONS.OVERVIEW_EDITOR,
        this.permissions
      );
    },
  },
  components: {
    ServiceFitRateVue,
    FormSection,
  },
};
</script>


<style lang="scss" scoped>
.service-section-overview {
  margin-top: 20px;
  padding: 0;
  .overview-text-col {
    display: flex;
    flex-direction: column;
    min-width: 200px;
    flex-grow: 1;
    max-width: 100%;
  }
  .overview-metrics-col {
    display: flex;
    flex-direction: column;
    min-width: 200px;
    flex-grow: 1;
    max-width: 100%;
    margin-top: 20px;
    padding-left: 12px!important;
    padding-right: 12px!important;
  }
}
</style>