
<template>
  <div>
    <template v-if="editable">
      <v-progress-linear
        v-model="value.idea"
        :color="dark ? 'white' : '#222'"
        height="10"
      ></v-progress-linear>
      Idea
      <br />
      <v-progress-linear
        :dark="dark"
        v-model="value.startup"
        :color="dark ? 'white' : '#222'"
        height="10"
      ></v-progress-linear>
      Startup
      <br />
      <v-progress-linear
        :dark="dark"
        v-model="value.product"
        :color="dark ? 'white' : '#222'"
        height="10"
      ></v-progress-linear>
      Product
      <br />
      <v-progress-linear
        :dark="dark"
        v-model="value.enterprise"
        :color="dark ? 'white' : '#222'"
        height="10"
      >
      </v-progress-linear>
      Enterprise
    </template>
    <template v-else>
      <v-progress-linear
        :dark="dark"
        :value="value.idea"
        :color="dark ? 'white' : '#222'"
        height="2"
        :class="{ small }"
        class="progress-default"
      ></v-progress-linear>
      <span class="measure-name" :class="{ small }"> Idea</span>

      <v-progress-linear
        :dark="dark"
        :value="value.startup"
        :color="dark ? 'white' : '#222'"
        height="2"
        :class="{ small }"
        class="progress-default"
      ></v-progress-linear>

      <span class="measure-name" :class="{ small }"> Startup</span>

      <v-progress-linear
        :dark="dark"
        :value="value.product"
        :color="dark ? 'white' : '#222'"
        height="2"
        :class="{ small }"
        class="progress-default"
      ></v-progress-linear>

      <span class="measure-name" :class="{ small }"> Product</span>

      <v-progress-linear
        :dark="dark"
        :value="value.enterprise"
        :color="dark ? 'white' : '#222'"
        height="2"
        :class="{ small }"
        class="progress-default"
      >
      </v-progress-linear>
      <span class="measure-name" :class="{ small }"> Enterprise</span>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    value: {
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  watch: {
    "value.idea"(val) {
      this.value.idea = Math.round(val);
    },
    "value.startup"(val) {
      this.value.startup = Math.round(val);
    },
    "value.product"(val) {
      this.value.product = Math.round(val);
    },
    "value.enterprise"(val) {
      this.value.enterprise = Math.round(val);
    },
  },
};
</script>


<style lang="scss" scoped>
.v-progress-linear {
  cursor: pointer;
}

.progress-default:not(:first-child) {
  margin-top: 20px;

  &.small {
    margin-top: 2px;
  }
}

.measure-name {
  font-size: 14px;
  &.small {
    font-size: 12px;
  }
}
</style>