<template>
  <div class="wrapper-progressBar align-items-center">
    <ul class="progressBar align-center align-items-center">
      <li @click="moveTo(prev)" class="">
        {{ prev ? prev.code : "" }}
        {{ prev ? prev.name : "" }}
      </li>
      <li @click="moveTo(curr)" class="active current">
        {{ curr ? curr.code : "" }}
        {{ curr ? curr.name : "" }}
      </li>
      <li @click="moveTo(next)">
        {{ next ? next.code : "" }}
        {{ next ? next.name : "" }}
      </li>
    </ul>
  </div>
</template>


<script>
export default {
  props: {
    prev: {
      type: Object,
      default: () => ({}),
    },
    curr: {
      type: Object,
      default: () => ({}),
    },
    next: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  methods: {
    moveTo(service) {
      this.$emit("onServiceClick", service);
    },
  },
};
</script>


<style lang="scss" scoped>
.wrapper-progressBar {
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
  display: flex;

  ul {
    padding-left: 0;
  }
}

.progressBar {
}

.progressBar li {
  color: white;
  list-style-type: none;
  float: left;
  width: 33%;
  position: relative;
  text-align: center;
  font-family: Dosis, arial, sans-serif;
  letter-spacing: 0.15em;
  cursor: pointer;
}

.progressBar li:before {
  content: " ";
  line-height: 40px;
  width: 40px;
  height: 40px;
  border: 1px solid #fff;
  display: block;
  text-align: center;
  margin: 0 auto 10px;
  background-color: transparent;
  transform: rotate(45deg);

  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  -webkit-transition: all 0.2s cubic-bezier(0, 0, 0.58, 1);
  -moz-transition: all 0.2s cubic-bezier(0, 0, 0.58, 1);
  -o-transition: all 0.2s cubic-bezier(0, 0, 0.58, 1);
  -ms-transition: all 0.2s cubic-bezier(0, 0, 0.58, 1);
  transition: all 0.2s cubic-bezier(0, 0, 0.58, 1);
}

.progressBar li:after {
  content: "";
  position: absolute;
  width: 50%;
  height: 4px;
  background-color: #ddd;
  top: 18px;
  left: -25%;
  border-radius: 5px;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  -webkit-transition: all 0.2s cubic-bezier(0, 0, 0.58, 1);
  -moz-transition: all 0.2s cubic-bezier(0, 0, 0.58, 1);
  -o-transition: all 0.2s cubic-bezier(0, 0, 0.58, 1);
  -ms-transition: all 0.2s cubic-bezier(0, 0, 0.58, 1);
  transition: all 0.2s cubic-bezier(0, 0, 0.58, 1);

  transition: background-color 0.2s ease-in-out;
}

.progressBar li:first-child:after {
  content: none;
}

.progressBar li.active {
  color: #fff;
}

.progressBar li.active:before {
  background-color: linear-gradient(to right, #cd303b, #fc4a03);
}

.progressBar .active:after {
  background-color: linear-gradient(to right, #cd303b, #fc4a03);
}

.progressBar li.current:before {
  background: linear-gradient(45deg, #cd303b, #fc4a03, #fff);
  border-radius: calc(2 * var(--borderWidth));
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.progressBar li:hover:before,
.progressBar li:focus:before {
  color: #111;
  border-color: #111;
  background: linear-gradient(to right, #cd303b, #fc4a03);
}

.progressBar li:hover:after,
.progressBar li:focus:after {
  color: #111;
  border-color: #111;
  background: linear-gradient(to right, #cd303b, #fc4a03);
}
</style>