<template>
  <!-- <v-parallax
    class="service-header"
    :src="value &&  value.type &&  value.type.image ? value.type.image: '/static/images/backgrounds/69.webp'"
    height="450"
  > -->
  <v-card
    class="service-sections service-details-header elevation-0 dark-alpha-03"
    :style="{
      '--bgImage': `url(${
        value && value.type && value.type.image ? value.type.image : ''
      })`,
    }"
  >
    <v-img
      class="lazy-bg-image-absolute"
      :src="value && value.type && value.type.image ? value.type.image : ''"
      :lazy-src="
        value && value.type && value.type.image ? value.type.image : ''
      "
    >
    </v-img>

    <template v-slot:progress>
      <v-progress-linear
        absolute
        color="primary"
        height="8"
        indeterminate
      ></v-progress-linear>
    </template>

    <v-card-text class="flex-wrap" v-if="value.id">
      <v-row class="pt-6">
        <v-col cols="12" lg="6" class="pa-0 mt-2 service-description-col">
          <div class="service-description-container">
            <FormSection
              left
              :label="value.code + ' ' + value.name"
              :icon="!!actions.length ? 'mdi-cog-outline' : ''"
              :actions="actions"
              :editable="!!actions.length"
              @action="(action) => $emit('action', action)"
            ></FormSection>

            <p class="service-description simple-text white-muted">
              {{ value.short_description }}
            </p>
            <v-btn class="btn-hover-able" outlined color="white">
              {{ value.action_btn_text }}
            </v-btn>
          </div>
        </v-col>
        <v-col cols="12" lg="6" class="pa-0 mt-8 service-navigator-col">
          <ServiceHeaderNavigatorVue
            @onServiceClick="(service) => $emit('onServiceClick', service)"
            class="service-header-navigator"
            :prev="value.prevService"
            :curr="value"
            :next="value.nextService"
          ></ServiceHeaderNavigatorVue>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
  <!-- </v-parallax> -->
</template>


<script>
import { SERVICE_BUTTONS } from "../../../../../views/src/apps/service-management/constants/actions.gh";
import { PermissionsHelper } from "../../../../helpers/permissions.helper";
import FormSection from "../../../atoms/common/FormSection.vue";
import ServiceHeaderNavigatorVue from "../../../atoms/service/ServiceHeaderNavigator.vue";

export default {
  props: {
    value: {},
    permissions: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  computed: {
    actions: function () {
      return PermissionsHelper.getActions(
        SERVICE_BUTTONS.HEADER_EDITOR,
        this.permissions
      );
    },
  },
  methods: {
    onCreate() {},
  },
  components: {
    ServiceHeaderNavigatorVue,
    FormSection,
  },
};
</script>


<style lang="scss" scoped>
.service-details-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 40vh;
  padding: 10px;

  background-attachment: fixed;
  background-size: cover;
  flex-grow: 1;
  max-height: 80vh;
  background-position: center;

  &::after {
    z-index: -1;
    content: "";
    // background: var(--bgImage);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top left;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    background-position: center;
    position: absolute;
    opacity: 0.7;
    left: 0;
    top: 0;
  }
}
.service-description {
  position: relative;
}
.service-description-container {
  @media (max-width: 1264px) {
    padding-top: 40px;
  }
}

.service-navigator-col {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  flex-grow: 1;
  max-width: 100%;
}
.service-description-col {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  flex-grow: 1;
  max-width: 100%;
}

.service-header-navigator {
  margin-top: 0px;

  @media (max-width: 864px) {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
</style>