var render = function render(){var _vm=this,_c=_vm._self._c;return _c('AView',{ref:"service-editor-view",attrs:{"value":_vm.value,"actions":_vm.actions,"active":_vm.service.id === _vm.activeServiceId,"name":_vm.service.name},on:{"onHover":_vm.onHover,"onActivate":(e) => _vm.$emit('onActivate', e),"onMaximize":(e) => _vm.$emit('onMaximize', e),"onExpand":(e) => _vm.$emit('onExpand', e),"onCollapse":(e) => _vm.$emit('onCollapse', e),"onClose":(e) => _vm.$emit('onClose', e),"action":(action) => action.handler({ from: _vm.value, service: _vm.service }),"onServicePdfDownload":_vm.onServicePdfDownload},scopedSlots:_vm._u([{key:"content",fn:function({}){return [_c('div',{attrs:{"id":`service_${_vm.serviceId}`}},[_c('div',{attrs:{"id":`${_vm.value.id}_head`}}),_c('ServiceSectionHeaderVue',{attrs:{"permissions":_vm.value.permissions},on:{"action":(action) => action.handler({ from: _vm.value, service: _vm.service }),"onServiceClick":_vm.onServiceClick},model:{value:(_vm.service),callback:function ($$v) {_vm.service=$$v},expression:"service"}}),_c('v-lazy',{attrs:{"options":{
          threshold: 0.5,
        },"min-height":"200","transition":"fade-transition"}},[_c('ServiceSectionOverviewVue',{attrs:{"id":`${_vm.value.id}_overview`,"permissions":_vm.value.permissions},on:{"action":(action) => action.handler({ from: _vm.value, service: _vm.service })},model:{value:(_vm.service),callback:function ($$v) {_vm.service=$$v},expression:"service"}})],1),(_vm.service.sections && _vm.service.sections.length)?_vm._l((_vm.service.sections),function(section,i){return _c('div',{key:`free_section_${section.id}`},[_c('v-lazy',{attrs:{"options":{
              threshold: 0.5,
            },"min-height":"200","transition":"fade-transition"}},[_c('ServiceSectionFreeSectionVue',{attrs:{"id":`${_vm.value.id}_free_section_${i}`,"value":_vm.service.sections[i].freeSection,"service":_vm.service,"color":i % 2 !== 0 ? 'white' : 'secondary',"direction":i % 2 !== 0 ? 'left' : 'right',"permissions":_vm.value.permissions,"payload":{
                section,
                freeSection: _vm.service.sections[i].freeSection,
                freeSectionIndex: i,
                from: _vm.value,
                service: _vm.service,
              }}})],1)],1)}):(
          _vm.value.permissions[
            _vm.SERVICE_PERMISSIONS.ALLOW_EDIT_SERVICE_FREE_SECTIONS
          ]
        )?[_c('v-row',[_c('v-col',[_c('FormSection',{attrs:{"label":'Custom Sections',"icon":'mdi-animation-outline',"actions":_vm.freeSectionActions,"payload":{
                service: _vm.service,
                from: _vm.value,
              },"center":"","underline":""}})],1)],1)]:_vm._e(),_c('v-lazy',{attrs:{"options":{
          threshold: 0.5,
        },"min-height":"200","transition":"fade-transition"}},[_c('ServiceSectionDeliverablesVue',{attrs:{"id":`${_vm.value.id}_deliverables`,"color":_vm.service.sections.length % 2 === 0 ? 'white' : 'secondary',"direction":_vm.service.sections.length % 2 === 0 ? 'align-right ' : 'align-left',"permissions":_vm.value.permissions,"payload":{
            from: _vm.value,
            service: _vm.service,
          }},on:{"onDeliverableClick":_vm.onDeliverableClick},model:{value:(_vm.service),callback:function ($$v) {_vm.service=$$v},expression:"service"}})],1),_c('v-lazy',{attrs:{"options":{
          threshold: 0.5,
        },"min-height":"200","transition":"fade-transition"}},[_c('ServiceSectionUseCasesVue',{attrs:{"id":`${_vm.value.id}_use_cases`,"value":_vm.service.useCases,"color":_vm.service.sections.length % 2 !== 0 ? 'white' : 'secondary',"direction":_vm.service.sections.length % 2 !== 0 ? 'align-right ' : 'align-left',"permissions":_vm.value.permissions,"payload":{
            from: _vm.value,
            service: _vm.service,
          }}})],1),_c('v-lazy',{attrs:{"options":{
          threshold: 0.5,
        },"min-height":"200","transition":"fade-transition"}},[_c('ServiceSectionPlansVue',{attrs:{"id":`${_vm.value.id}_pricing`,"value":_vm.service.plans,"color":_vm.service.sections.length % 2 === 0 ? 'white' : 'secondary',"direction":_vm.service.sections.length % 2 === 0 ? 'align-right ' : 'align-left',"permissions":_vm.value.permissions,"payload":{
            from: _vm.value,
            service: _vm.service,
          }},on:{"onDeliverableClick":_vm.onDeliverableClick}})],1)],2),(
        _vm.$refs['service-editor-view'] && _vm.$refs['service-editor-view'].maximized
      )?_c('PageNavigationRightVue',{attrs:{"isOpen":_vm.isNavigationOpen,"buttons":_vm.navigationButtons},on:{"onRemove":_vm.onServiceRemove,"onHideEditor":function($event){return _vm.changeEditorState(true)},"onShowEditor":function($event){return _vm.changeEditorState(false)}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ServiceEditorNavigationVue',{attrs:{"value":_vm.service,"editable":""},on:{"goTo":_vm.scrollTo,"onAddPlan":_vm.onPlanCreate,"onAddUseCase":_vm.onUseCaseCreate,"onAddFreeSection":_vm.onFreeSectionCreate}})]},proxy:true}],null,true)}):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }