var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"free-sections service-sections elevation-0 pa-0 ma-0",attrs:{"color":"transparent"}},[_c('v-row',[_c('v-col',[_c('FormSection',{attrs:{"underline":"","label":_vm.value.name,"right":_vm.direction === 'left' ? false : true,"icon":!!_vm.actions.length ? 'mdi-cog-outline' : '',"actions":_vm.actions,"editable":!!_vm.actions.length,"payload":_vm.payload}})],1)],1),(_vm.value.content)?_c('v-row',[_c('v-col',{attrs:{"lg":"12","md":"12","xs":"12","sm":"12"}},[_c('div',{staticClass:"text-3-columns"},[_c('p',{staticClass:"simple-text",domProps:{"innerHTML":_vm._s(_vm.value.content)}})])])],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"lg":"12"}},[_c('transition',{attrs:{"name":"component-fade","mode":"out-in"}},[_c(_vm.value.steps_type === 'TABS'
              ? 'ServiceFreeSectionTabsVue'
              : _vm.value.steps_type === 'SLIDER'
              ? 'ServiceFreeSectionSliderVue'
              : _vm.value.steps_type === 'ICONS'
              ? 'ServiceFreeSectionIcons'
              : '',{tag:"component",attrs:{"steps":_vm.value.steps,"autoSpin":_vm.value.steps_auto_spin,"service":_vm.service}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }