<template>
  <v-row>
    <v-col cols="12" class="pa-0 ma-0">
      <v-row class="icons-row">
        <v-col
          class="icon-col"
          v-for="step in steps"
          :key="step.id"
          @click="selected = step"
        >
          <v-icon
            size="40"
            class="pt-2 pb-2 step-icon"
            :class="{
              active: selected.id === step.id,
            }"
            v-if="step.icon"
          >
            {{ step.icon }}
          </v-icon>
          <h1 class="how-it-works-tabs-header center">
            {{ step.header }}
          </h1>
          <h3
            v-if="step.sub_header"
            class="how-it-works-tabs-subheader mt-5 mb-5 center"
          >
            {{ step.sub_header }}
          </h3>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" class="">
      <v-divider width="100%" class="mb-5"></v-divider>
      <v-fade-transition leave-absolute hide-on-leave>
        <p class="step-content simple-text" v-if="selected" :key="selected.id">
          {{ selected.content }}
        </p>
      </v-fade-transition>
    </v-col>
  </v-row>
</template>
  
  
  
  <script>
export default {
  props: {
    steps: {
      type: Array,
      default: () => [],
    },
    autoSpin: {
      type: Boolean,
      default: false,
    },
    service: {
      default: () => ({}),
    },
  },
  data() {
    return {
      tab: null,
      selected: this.steps && this.steps.length ? this.steps[0] : {},
    };
  },
};
</script>
  
     
  
  <style lang="scss" scoped>
.icons-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  .icon-col {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    max-width: 250px;


    .step-icon {
      transition: all 0.2s;
      cursor: pointer;

      &:hover {
        color: #cd303b;
      }
      &.active {
        color: #cd303b;
      }
    }
    .how-it-works-tabs-header {
      text-transform: uppercase;
      font-weight: 200;
      font-size: 16px;

      &.center {
        text-align: center;
      }
    }

    .how-it-works-tabs-subheader {
      &.center {
        text-align: center;
      }
    }
  }
}
.step-content {
  text-align: center;
}
</style>
  