<template>
  <v-card
    class="elevation-0 service-sections service-deliverables-section pa-0 ma-0"
    color="transparent"
  >
    <v-row>
      <v-col>
        <FormSection
          underline
          :label="'Deliverables'"
          :icon="!!actions.length ? 'mdi-cog-outline' : ''"
          :right="direction === 'left' ? false : true"
          :actions="actions"
          :editable="!!actions.length"
          :payload="payload"
        ></FormSection>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        class="deliverables-images-col"
      >
        <ServiceDeliverablesSliderVue
          :value="deliverables"
        ></ServiceDeliverablesSliderVue>
      </v-col>
      <v-col
        class="deliverables-list-col"
      >
        <ServiceDeliverablesListVue
          :value="deliverables"
          @onDeliverableClick="
            (deliverable) => $emit('onDeliverableClick', deliverable)
          "
        ></ServiceDeliverablesListVue>
      </v-col>
    </v-row>
  </v-card>
</template>


<script>
import FormSection from "../../../atoms/common/FormSection.vue";
import ServiceDeliverablesListVue from "../../../molecules/services/sections/deliverables/ServiceDeliverablesList.vue";
import ServiceDeliverablesSliderVue from "../../../molecules/services/sections/deliverables/ServiceDeliverablesSlider.vue";
import {
  DELIVERABLE_BUTTONS,
  SERVICE_BUTTONS,
} from "../../../../../views/src/apps/service-management/constants/actions.gh";
import { PermissionsHelper } from "../../../../helpers/permissions.helper";

export default {
  props: {
    value: {
      default: () => [],
    },
    color: {
      type: String,
      default: "white",
    },
    direction: {
      type: String,
      default: "align-left",
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
    payload: {
      default: () => ({}),
    },
  },
  computed: {
    actions: function () {
      return PermissionsHelper.getActions(
        [SERVICE_BUTTONS.DELIVERABLES_EDITOR, DELIVERABLE_BUTTONS.LIST],
        this.permissions
      );
    },
  },
  data() {
    let deliverables = [];
    if (this.value.serviceDeliverables)
      deliverables = this.value.serviceDeliverables.map((el) => el.deliverable);

    return {
      deliverables,
    };
  },
  methods: {
    onCreate() {},
  },
  components: {
    ServiceDeliverablesListVue,
    ServiceDeliverablesSliderVue,
    FormSection,
  },
  watch: {
    value: {
      handler(newVal) {
        if (newVal.serviceDeliverables)
          this.deliverables = newVal.serviceDeliverables.map(
            (el) => el.deliverable
          );
      },
      deep: true,
    },
  },
};
</script>


<style lang="scss" scoped>
.service-deliverables-section {
  .deliverables-images-col {
    display: flex;
    flex-direction: column;
    min-width: 300px;
    flex-grow: 1;
    max-width: 100%;
  }
  .deliverables-list-col {
    display: flex;
    flex-direction: column;
    min-width: 300px;
    flex-grow: 1;
    max-width: 100%;
  }
}
</style>