<template>
  <v-list class="deliverables-list" color="transparent">
    <v-list-item-group color="primary">
      <v-list-item
        v-for="(deliverable, i) in value"
        :key="i"
        @click="$emit('onDeliverableClick', deliverable)"
      >
        <v-list-item-icon>
          <v-icon v-if="deliverable.icon" color="primary">{{
            deliverable.icon
          }}</v-icon>
          <v-icon v-else color="primary">icon-flag</v-icon>
        </v-list-item-icon>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item-content v-bind="attrs" v-on="on">
              <v-list-item-title> {{ deliverable.name }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <span>{{ deliverable.name }}</span>
        </v-tooltip>
        <v-list-item-icon>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="grey" size="18" dark v-bind="attrs" v-on="on">
                mdi-help
              </v-icon>
            </template>
            <span class="tooltip-text">{{ deliverable.definition }}</span>
          </v-tooltip>
        </v-list-item-icon>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>


<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      deliverables: this.value,
    };
  },
};
</script>

   

<style lang="scss" scoped>
.deliverables-list {
}
</style>