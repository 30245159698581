<template>
  <v-card
    :class="{
      recommended,
    }"
    class="mx-auto my-5 pt-10 pb-8 plan-item-card bg-grey-gradient"
    color="transparent"
  >
    <div class="disclaimer">
      <p v-html="value.disclaimer" class="mt-2"></p>
    </div>

    <div class="plan-icon-container">
      <v-icon size="40" :color="recommended ? 'primary' : ''">{{
        value.icon
      }}</v-icon>
    </div>
    <v-card-title class="plan-title justify-center">
      {{ value.name }}</v-card-title
    >

    <v-card-text class="plan-content">
      <!-- WEEKLY  -->
      <div v-if="weeklyDeliverables && weeklyDeliverables.length">
        <div class="benefit-item-wrapper title">
          <v-divider></v-divider>
          <span class="delivery-type-label"> each week </span>
        </div>
        <div
          class="benefit-item-wrapper"
          v-for="deliverable in weeklyDeliverables"
          :key="deliverable.id"
          @click="() => $emit('onDeliverableClick', deliverable)"
        >
          <v-divider></v-divider>
          <span class="price-item" v-if="getItemByDeliverable(deliverable)">
            <span class="price-item-delivery-value">
              {{
                getItemByDeliverable(deliverable).value_measure === "minutes"
                  ? hoursFormatter(getItemByDeliverable(deliverable).value, "m")
                  : getItemByDeliverable(deliverable).value
              }}
            </span>

            {{ getItemByDeliverable(deliverable).deliverable.name }}
          </span>
          <span class="price-item" v-else> - </span>
        </div>
      </div>

      <!-- MONTHLY -->
      <div v-if="monthlyDeliverables && monthlyDeliverables.length">
        <div class="benefit-item-wrapper title">
          <v-divider></v-divider>
          <span class="delivery-type-label"> each month </span>
        </div>
        <div
          class="benefit-item-wrapper"
          v-for="deliverable in monthlyDeliverables"
          :key="deliverable.id"
          @click="() => $emit('onDeliverableClick', deliverable)"
        >
          <v-divider></v-divider>
          <span class="price-item" v-if="getItemByDeliverable(deliverable)">
            <span class="price-item-delivery-value">
              {{
                getItemByDeliverable(deliverable).value_measure === "minutes"
                  ? hoursFormatter(getItemByDeliverable(deliverable).value, "m")
                  : getItemByDeliverable(deliverable).value
              }}
            </span>

            {{ getItemByDeliverable(deliverable).deliverable.name }}
          </span>
          <span class="price-item" v-else> - </span>
        </div>
      </div>
      <!-- PER ASSIGNMENT -->
      <div v-if="assignmentDeliverables && assignmentDeliverables.length">
        <div class="benefit-item-wrapper title">
          <v-divider></v-divider>
          <span class="delivery-type-label"> per assignment </span>
        </div>
        <div
          class="benefit-item-wrapper"
          v-for="deliverable in assignmentDeliverables"
          :key="deliverable.id"
          @click="() => $emit('onDeliverableClick', deliverable)"
        >
          <v-divider></v-divider>
          <span class="price-item" v-if="getItemByDeliverable(deliverable)">
            <span class="price-item-delivery-value">
              {{
                getItemByDeliverable(deliverable).value_measure === "minutes"
                  ? hoursFormatter(getItemByDeliverable(deliverable).value, "m")
                  : getItemByDeliverable(deliverable).value
              }}
            </span>

            {{ getItemByDeliverable(deliverable).deliverable.name }}
          </span>
          <span class="price-item" v-else> - </span>
        </div>
      </div>

      <v-divider></v-divider>

      <div class="price-val mt-10">
        <!-- <sup>$</sup> -->
        {{ priceFormatter(value.price) }}
      </div>

      <div class="pr-per">{{ value.perItem }}</div>
    </v-card-text>

    <v-card-actions class="justify-center">
      <v-btn
        class="pa-4"
        depressed
        color="black"
        dark
        @click="openServiceDetails(id)"
      >
        {{ value.btn_text || "Order Now" }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { FormatterHelper } from "../../../../../helpers/formatter.helper";

export default {
  props: {
    recommended: {
      type: Boolean,
      default: false,
    },
    benefitsLength: {
      type: Number,
      default: 2,
    },
    weeklyDeliverables: {
      type: Array,
      default: () => [],
    },
    monthlyDeliverables: {
      type: Array,
      default: () => [],
    },
    assignmentDeliverables: {
      type: Array,
      default: () => [],
    },

    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    priceFormatter: FormatterHelper.price,
    hoursFormatter: FormatterHelper.timeDuration,
    show: false,
  }),
  components: {},
  methods: {
    getItemByDeliverable(deliverable) {
      const foundItem = this.value.packageItems.find(
        (item) => item.deliverable.id === deliverable.id
      );
      return foundItem ? foundItem : undefined;
    },

    openServiceDetails() {
      this.$router.push(`/services/${this.service?.code}/details`);
    },
  },
};
</script>


<style lang="scss" scoped>
.plan-item-card {
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0.1) 50%,
    rgba(0, 0, 0, 0.1) 63%
  );
  border: 1px solid rgba(255, 255, 255, 0.2);

  &.recommended {
    border: 2px solid white !important;
  }
}
.plan-icon-container {
  display: flex;
  width: 100%;
  margin-top: 20px;
  justify-content: center;
  &.hot {
    color: red;
  }
}

.plan-title {
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.plan-content {
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.price-val {
  font-size: 40px;
  font-weight: 300;
  line-height: 1;
  font-family: "Open Sans", arial, sans-serif;
  margin: 0 auto;
}

.price-val sup {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  top: -20px;
  left: -3px;
  margin-left: -7px;
}

.pr-per {
  margin: 0 auto;
  color: #999;
  font-size: 12px;
}

.price-item {
  align-self: center;
  color: rgba($color: #fff, $alpha: 0.6);
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;

  padding: 0;

  transition: color 0.1s linear;

  &:hover {
    color: rgba($color: #fff, $alpha: 1);
  }

  .price-item-delivery-value {
    text-transform: none;
    color: rgba($color: #fff, $alpha: 0.8);
  }
}
.benefit-item-wrapper {
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &.title {
    cursor: default;
  }
}

.disclaimer {
  position: absolute;
  top: 0;
  width: 100%;
  height: 55px;
  justify-content: center;
  display: flex;
  padding: 18px;
  background: black;
  align-items: center;
  text-align: center;
}

.delivery-type-label {
  align-self: center;
  color: white;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 2px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;

  padding: 0;
}
</style>