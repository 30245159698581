<template>
  <v-card class="how-it-works-tabs elevation-0 pa-3" color="transparent">
    <v-tabs v-model="tab" background-color="transparent" dark>
      <v-tab v-for="(step, i) in steps" :key="i">
        <v-icon v-if="step.icon" class="mr-3">{{ step.icon }}</v-icon>
        {{ step.header }}
      </v-tab>
    </v-tabs>
    <v-card-text class="pa-0">
      <v-tabs-items
        class="transparent"
        background-color="transparent"
        v-model="tab"
      >
        <v-tab-item v-for="(step, i) in steps" :key="i" :value="i">
          <v-card color="transparent" flat>
            <v-card-text>
              <h3 class="how-it-works-tabs-subheader">{{ step.sub_header }}</h3>
              <v-divider v-if="step.sub_header" class="mt-5 mb-5" color="white"></v-divider>
              <p class="simple-text" v-html="step.content">
              </p>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>



<script>
export default {
  props: {
    steps: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    tab: null,
  }),
};
</script>



<style lang="scss" scoped>
.how-it-works-tabs {
  min-height: 350px;
  background: transparent;
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0.1) 50%,
    rgba(0, 0, 0, 0.1) 63%
  );
  .how-it-works-tabs-subheader {
    margin-bottom: 20px;
    font-weight: 300;
    color: rgba($color: #fff, $alpha: 0.7);
    text-transform: uppercase;
  }
}
</style>
