import { render, staticRenderFns } from "./ServicePlanItem.vue?vue&type=template&id=fa09aae4&scoped=true&"
import script from "./ServicePlanItem.vue?vue&type=script&lang=js&"
export * from "./ServicePlanItem.vue?vue&type=script&lang=js&"
import style0 from "./ServicePlanItem.vue?vue&type=style&index=0&id=fa09aae4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa09aae4",
  null
  
)

export default component.exports