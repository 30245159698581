<template>
  <v-carousel show-arrows-on-hover height="400" cycle hide-delimiter-background>
    <template v-slot:prev="{ on, attrs }">
      <div class="direction-wrapper" v-bind="attrs" v-on="on">
        <v-icon class="direction-icon" color="white">mdi-chevron-left</v-icon>
      </div>
    </template>
    <template v-slot:next="{ on, attrs }">
      <div class="direction-wrapper" v-bind="attrs" v-on="on">
        <v-icon class="direction-icon" color="white">mdi-chevron-right</v-icon>
      </div>
    </template>
    <v-carousel-item v-for="(img, i) in images" :key="i">
      <v-img class="center-img" :src="img.url" :lazy-src="img.url"> </v-img>
    </v-carousel-item>
  </v-carousel>
</template>


<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      images: new Array().concat(
        ...this.value.map((del) => del.publicMaterials)
      ),
    };
  },
  watch: {
    value(newVal) {
      if (newVal && newVal.length)
        this.images = new Array().concat(
          ...this.value.map((del) => del.publicMaterials)
        );
    },
  },
};
</script>


<style lang="scss" scoped>
.deliverables-slider {
}

.center-img {
  top: 50%;
  transform: translate(0, -50%);
}
</style>