<template>
  <v-card
    class="service-sections service-section-use-cases pa-0 ma-0"
    outlined
    color="transparent"
  >
    <v-row>
      <v-col>
        <FormSection
          underline
          :label="'Use Cases'"
          :right="direction === 'left' ? false : true"
          :icon="!!actions.length ? 'mdi-cog-outline' : ''"
          :actions="actions"
          :editable="!!actions.length"
          :payload="payload"
        ></FormSection>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col
        class="pa-0 use-case-col"
        v-for="useCase in value"
        :key="useCase.id"
        cols="12"
        lg="4"
        md="12"
        sm="12"
      >
        <ServiceUseCaseItemVue :value="useCase"> </ServiceUseCaseItemVue>
      </v-col>
    </v-row>
  </v-card>
</template>


<script>
import FormSection from "../../../atoms/common/FormSection.vue";
import ServiceUseCaseItemVue from "../../../molecules/services/sections/useCases/ServiceUseCaseItem.vue";
import { SERVICE_BUTTONS } from "../../../../../views/src/apps/service-management/constants/actions.gh";
import { PermissionsHelper } from "../../../../helpers/permissions.helper";

export default {
  props: {
    color: {
      type: String,
      default: "white",
    },
    direction: {
      type: String,
      default: "left",
    },
    value: {
      type: Array,
      default: () => [],
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
    payload: {
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  computed: {
    actions: function () {
      return PermissionsHelper.getActions(
        [SERVICE_BUTTONS.USE_CASES_EDITOR, SERVICE_BUTTONS.USE_CASE_CREATE_NEW],
        this.permissions
      );
    },
  },
  methods: {
    onCreate() {},
  },
  components: {
    ServiceUseCaseItemVue,
    FormSection,
  },
};
</script>


<style lang="scss" scoped>
.service-section-use-cases {
  .use-case-col {
    display: flex;
    flex-direction: column;
    min-width: 300px;
    flex-grow: 1;
    max-width: 100%;
  }
}
</style>