<template>
  <AView
    :value="value"
    :actions="actions"
    :active="service.id === activeServiceId"
    :name="service.name"
    ref="service-editor-view"
    @onHover="onHover"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onExpand="(e) => $emit('onExpand', e)"
    @onCollapse="(e) => $emit('onCollapse', e)"
    @onClose="(e) => $emit('onClose', e)"
    @action="(action) => action.handler({ from: value, service })"
    @onServicePdfDownload="onServicePdfDownload"
  >
    <template v-slot:content="{}">
      <div :id="`service_${serviceId}`">
        <!-- Service Header -->
        <div :id="`${value.id}_head`"></div>
        <ServiceSectionHeaderVue
          v-model="service"
          :permissions="value.permissions"
          @action="(action) => action.handler({ from: value, service })"
          @onServiceClick="onServiceClick"
        ></ServiceSectionHeaderVue>

        <!-- Overview Section -->
        <v-lazy
          :options="{
            threshold: 0.5,
          }"
          min-height="200"
          transition="fade-transition"
        >
          <ServiceSectionOverviewVue
            :id="`${value.id}_overview`"
            v-model="service"
            :permissions="value.permissions"
            @action="(action) => action.handler({ from: value, service })"
          ></ServiceSectionOverviewVue>
        </v-lazy>

        <!-- FREE SECTIONS -->

        <template v-if="service.sections && service.sections.length">
          <div
            v-for="(section, i) in service.sections"
            :key="`free_section_${section.id}`"
          >
            <v-lazy
              :options="{
                threshold: 0.5,
              }"
              min-height="200"
              transition="fade-transition"
            >
              <ServiceSectionFreeSectionVue
                :id="`${value.id}_free_section_${i}`"
                :value="service.sections[i].freeSection"
                :service="service"
                :color="i % 2 !== 0 ? 'white' : 'secondary'"
                :direction="i % 2 !== 0 ? 'left' : 'right'"
                :permissions="value.permissions"
                :payload="{
                  section,
                  freeSection: service.sections[i].freeSection,
                  freeSectionIndex: i,
                  from: value,
                  service,
                }"
              >
              </ServiceSectionFreeSectionVue>
            </v-lazy>
          </div>
        </template>
        <template
          v-else-if="
            value.permissions[
              SERVICE_PERMISSIONS.ALLOW_EDIT_SERVICE_FREE_SECTIONS
            ]
          "
        >
          <v-row>
            <v-col>
              <FormSection
                :label="'Custom Sections'"
                :icon="'mdi-animation-outline'"
                :actions="freeSectionActions"
                :payload="{
                  service,
                  from: value,
                }"
                center
                underline
              ></FormSection>
            </v-col>
          </v-row>
        </template>

        <!-- Deliverables -->
        <v-lazy
          :options="{
            threshold: 0.5,
          }"
          min-height="200"
          transition="fade-transition"
        >
          <ServiceSectionDeliverablesVue
            :id="`${value.id}_deliverables`"
            v-model="service"
            :color="service.sections.length % 2 === 0 ? 'white' : 'secondary'"
            :direction="
              service.sections.length % 2 === 0 ? 'align-right ' : 'align-left'
            "
            :permissions="value.permissions"
            :payload="{
              from: value,
              service,
            }"
            @onDeliverableClick="onDeliverableClick"
          ></ServiceSectionDeliverablesVue>
        </v-lazy>

        <!-- Use Cases -->
        <v-lazy
          :options="{
            threshold: 0.5,
          }"
          min-height="200"
          transition="fade-transition"
        >
          <ServiceSectionUseCasesVue
            :id="`${value.id}_use_cases`"
            :value="service.useCases"
            :color="service.sections.length % 2 !== 0 ? 'white' : 'secondary'"
            :direction="
              service.sections.length % 2 !== 0 ? 'align-right ' : 'align-left'
            "
            :permissions="value.permissions"
            :payload="{
              from: value,
              service,
            }"
          ></ServiceSectionUseCasesVue>
        </v-lazy>

        <!-- Pricing -->
        <v-lazy
          :options="{
            threshold: 0.5,
          }"
          min-height="200"
          transition="fade-transition"
        >
          <ServiceSectionPlansVue
            :id="`${value.id}_pricing`"
            :value="service.plans"
            :color="service.sections.length % 2 === 0 ? 'white' : 'secondary'"
            :direction="
              service.sections.length % 2 === 0 ? 'align-right ' : 'align-left'
            "
            :permissions="value.permissions"
            :payload="{
              from: value,
              service,
            }"
            @onDeliverableClick="onDeliverableClick"
          ></ServiceSectionPlansVue>
        </v-lazy>
      </div>
      <PageNavigationRightVue
        v-if="
          $refs['service-editor-view'] && $refs['service-editor-view'].maximized
        "
        :isOpen="isNavigationOpen"
        :buttons="navigationButtons"
        @onRemove="onServiceRemove"
        @onHideEditor="changeEditorState(true)"
        @onShowEditor="changeEditorState(false)"
      >
        <template v-slot:content>
          <ServiceEditorNavigationVue
            :value="service"
            editable
            @goTo="scrollTo"
            @onAddPlan="onPlanCreate"
            @onAddUseCase="onUseCaseCreate"
            @onAddFreeSection="onFreeSectionCreate"
          ></ServiceEditorNavigationVue>
        </template>
      </PageNavigationRightVue>
    </template>
  </AView>
</template>


<script>
import { mapGetters, mapState } from "vuex";
import AView from "../../../../../../components/wad/organisms/layout/A-View.vue";
import ServiceSectionDeliverablesVue from "../../../../../../components/wad/organisms/services/sections/ServiceSectionDeliverables.vue";
import ServiceSectionFreeSectionVue from "../../../../../../components/wad/organisms/services/sections/ServiceSectionFreeSection.vue";
import ServiceEditorNavigationVue from "../../../../../../components/wad/molecules/services/editor/ServiceEditorNavigation.vue";
import ServiceSectionHeaderVue from "../../../../../../components/wad/organisms/services/sections/ServiceSectionHeader.vue";
import ServiceSectionUseCasesVue from "../../../../../../components/wad/organisms/services/sections/ServiceSectionUseCases.vue";
import ServiceSectionPlansVue from "../../../../../../components/wad/organisms/services/sections/ServiceSectionPlans.vue";
import ServiceSectionOverviewVue from "../../../../../../components/wad/organisms/services/sections/ServiceSectionOverview.vue";
import PageNavigationRightVue from "../../../../../../components/wad/molecules/layout/PageNavigationRight.vue";
import { SERVICE_PERMISSIONS } from "../../constants/permissions.gh";
import FormSection from "../../../../../../components/wad/atoms/common/FormSection.vue";
import { PermissionsHelper } from "../../../../../../components/helpers/permissions.helper";
import { SERVICE_BUTTONS } from "../../constants/actions.gh";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

export default {
  props: {
    value: {
      default: () => ({}),
    },
  },
  components: {
    AView,
    ServiceSectionDeliverablesVue,
    ServiceSectionFreeSectionVue,
    ServiceEditorNavigationVue,
    ServiceSectionHeaderVue,
    ServiceSectionUseCasesVue,
    ServiceSectionPlansVue,
    ServiceSectionOverviewVue,
    PageNavigationRightVue,
    FormSection,
  },
  data() {
    console.log("DATA START: SERVICE_PERMISSIONS : ", SERVICE_PERMISSIONS);

    const serviceId = this.value.relation.params.serviceId;

    return {
      SERVICE_PERMISSIONS,
      serviceId,
      service: {
        sections: [],
      },
      isNavigationOpen: false,
      navigationButtons: [
        {
          name: "Expand Filters",
          action: "onExpand",
          openIcon: "mdi-chevron-right",
          closeIcon: "icon-magnifying-glass",
        },
      ],
    };
  },
  computed: {
    ...mapState("ServiceStore", [
      "activeServiceId",
      "displayedServices",
      "activeSectionId",
    ]),
    ...mapGetters("ServiceStore", ["serviceById"]),
    actions: function () {
      return PermissionsHelper.getActions(
        [
          SERVICE_BUTTONS.SETTINGS_EDITOR_MAIN,
          SERVICE_BUTTONS.HEADER_EDITOR_MAIN,
          SERVICE_BUTTONS.OVERVIEW_EDITOR,
          SERVICE_BUTTONS.USE_CASES_EDITOR_MAIN,
          SERVICE_BUTTONS.DELIVERABLES_EDITOR_MAIN,
          SERVICE_BUTTONS.PLANS_EDITOR_MAIN,
          SERVICE_BUTTONS.DOWNLOAD_PDF,
        ],
        this.value.permissions
      );
    },
    freeSectionActions: function () {
      return PermissionsHelper.getActions(
        SERVICE_BUTTONS.FREE_SECTION_CREATE_NEW,
        this.value.permissions
      );
    },
  },
  created() {
    this.$store.dispatch("ServiceStore/GetService", {
      id: this.value.relation.params.serviceId,
    });

    this.service = this.serviceById(this.value.relation.params.serviceId);
  },
  methods: {
    async onServicePdfDownload() {
      // const doc = new jsPDF();
      html2canvas;
      jsPDF;
      const canvas = await html2canvas(
        document.getElementById(`service_${this.serviceId}`),
        {
          useCORS: true,
          backgroundColor: "#000000",
          allowTaint: true,
          scale: 2,
        }
      );
      // doc.html(document.getElementById(`service_${this.serviceId}`), {
      //   callback: function (doc) {
      //     doc.save("test.pdf");
      //   },
      // });

      const img = canvas.toDataURL();

      // var imgWidth = (canvas.width * 60) / 240;

      // doc.addImage(img, "png", 0, 0);

      // doc.save("tt.pdf");

      this.downloadURI(
        img,
        `[${this.service.code}] ${this.service.name}` + ".png"
      );
    },
    downloadURI(uri, name) {
      const link = document.createElement("a");

      link.download = name;
      link.href = uri;
      document.body.appendChild(link);
      link.click();

      //after creating link you should delete dynamic link
      //clearDynamicLink(link);
    },
    onServiceClick(service) {
      this.$store.dispatch("ServiceStore/SetActiveService", {
        from: this.value,
        service,
      });
    },
    onHover() {
      this.$store.commit(
        "ServiceStore/setActiveId",
        this.value.relation.params.serviceId
      );
    },
    onDeliverableClick(deliverable) {
      this.$store.dispatch("DeliverableStore/openDeliverableDetails", {
        deliverable,
        from: this.value,
      });
    },

    onServiceRemove() {},
    changeEditorState(value) {
      if (value) {
        this.navigationButtons.pop();
        // this.navigationButtons.push(RightButtons.ShowEditor.copy());

        this.isEditorHidden = value;
      } else {
        this.navigationButtons.pop();
        // this.navigationButtons.push(RightButtons.HideEditor.copy());

        this.isEditorHidden = value;
      }
    },
    async onEdit(id, props = {}) {
      console.log("onHeaderEdit");
      this.$store.dispatch("ServiceStore/OpenEditor", {
        from: this.value,
        editor: id,
        props: {
          serviceId: this.service.id,
          ...props,
        },
      });
    },

    // ==============FREE SECTION===============
    async onFreeSectionCreate() {
      this.$store.dispatch("ServiceStore/onFreeSectionCreate", {
        service: this.service,
      });
    },

    async onFreeSectionRemove() {
      this.$store.dispatch("ServiceStore/onFreeSectionRemove", {
        service: this.service,
        section: this.service.sections[this.index],
      });
    },

    // ==============USE CASES===============
    async onUseCaseCreate() {
      await this.api.UseCasesAPI.create(
        this.service.id,
        {},
        {
          toast: {
            message: "Use Case Created",
          },
        }
      );
    },
    async onUseCaseRemove(plan) {
      await this.api.UseCasesAPI.delete(this.service.id, plan, {
        toast: {
          message: "Use Case Removed",
        },
      });
    },

    // ==============PRICING===============
    async onPlanCreate() {
      console.log("onPlanCreate");

      await this.api.PlansAPI.create(
        this.service.id,
        {},
        {
          toast: {
            message: "Plan Created",
          },
        }
      );
    },

    // ==============RIGHT EDITOR==============
    onHideEditor(isHidden) {
      this.isEditorHidden = isHidden;
    },
    scrollTo(tag) {
      document.getElementById(`${this.value.id}_${tag}`).scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    },
  },
  watch: {
    activeSectionId(newVal) {
      if (this.activeServiceId === this.service.id && newVal) {
        this.scrollTo(newVal);
      }
    },
    displayedServices: {
      async handler(newVal) {
        if (newVal) {
          this.service = this.serviceById(this.value.relation.params.serviceId);
        }
      },
      deep: true,
    },
  },
};
</script>