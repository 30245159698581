<template>
  <div
    class="value-navigation"
    :class="isOpenInternal ? 'value-navigation-opened' : ''"
  >
    <v-treeview
      :active.sync="tree"
      class="navigation"
      open-all
      activatable
      dark
      return-object
      color="primary"
      open-on-click
      :items="prepareStructure(value)"
    >
      <template v-slot:prepend="{ item }">
        <v-icon class="navigation-element-icon">
          {{ item.icon }}
        </v-icon>
      </template>

      <template v-slot:label="{ item }">
        <span
          class="navigation-element-name"
          :class="item.color ? `${item.color}--text` : 'white--text'"
        >
          {{ item.name }}
        </span>
      </template>
    </v-treeview>
  </div>
</template>


<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      editionHidden: false,
      tree: [],
      isOpenInternal: this.isOpen,
    };
  },
  watch: {
    tree: {
      handler: function (val) {
        if (val && val[0]) this.goTo(val[0]);
      },
      deep: true,
    },
  },
  updated() {},
  methods: {
    hideEdit() {
      this.editionHidden = !this.editionHidden;
      this.$emit("onHideEditor", this.editionHidden);
    },
    expand() {
      this.isOpenInternal = !this.isOpenInternal;
    },
    edit() {},
    goTo(item) {
      if (!item.tag) {
        this.proceedAddOperation(item);
        this.tree = [];
      } else this.$emit("goTo", item.tag);
    },
    proceedAddOperation(item) {
      switch (item.id) {
        case "add_section":
          this.$emit("onAddFreeSection");
          break;
        case "add_use_case":
          this.$emit("onAddUseCase");
          break;
        case "add_plan":
          this.$emit("onAddPlan");
          break;

        default:
          break;
      }
    },
    prepareStructure(value) {
      return [
        {
          id: "header",
          tag: "head",
          name: "Header",
          icon: "mdi-bookmark-outline",
        },
        {
          id: "overview",
          tag: "overview",
          name: "Overview",
          icon: "mdi-eye",
        },
        // FREE SECTIONS
        ...(this.editable
          ? [
              {
                id: "sections",
                tag: "sections",
                name: "Free Sections",
                icon: "mdi-bookmark-check-outline",
                children: [
                  ...(value.sections && value.sections.length
                    ? value.sections.map((section) => ({
                        id: `section_${section.id}`,
                        tag: `section_${section.id}`,
                        name: section.freeSection.name,
                        icon: "mdi-bookmark-check-outline",
                      }))
                    : []),
                  this.editable
                    ? {
                        id: "add_section",
                        color: "primary",
                        name: "Add Section",
                        icon: "mdi-plus",
                      }
                    : undefined,
                ],
              },
            ]
          : value.sections && value.sections.length
          ? value.sections.map((section) => ({
              id: `section_${section.id}`,
              tag: `section_${section.id}`,
              name: section.freeSection.name,
              icon: "mdi-bookmark-check-outline",
            }))
          : []),
        // Deliverables
        {
          id: "deliverables",
          tag: "deliverables",
          name: "Deliverables",
          icon: "mdi-briefcase-outline",
          children: [
            ...(value.serviceDeliverables && value.serviceDeliverables.length
              ? value.serviceDeliverables.map((deliverable) => ({
                  id: `deliverable_${deliverable.id}`,
                  tag: "deliverables",
                  name: deliverable.deliverable.name,
                  icon: "mdi-briefcase-check-outline",
                }))
              : [])
          ],
        },
        {
          id: "use_cases",
          tag: "use_cases",
          name: "Use Cases",
          icon: "mdi-file-star-outline",
          children: [
            ...(value.useCases && value.useCases.length
              ? value.useCases.map((useCase) => ({
                  id: `use_case_${useCase.id}`,
                  tag: "use_cases",
                  name: useCase.name,
                  icon: "mdi-file-star-outline",
                }))
              : []),
            this.editable
              ? {
                  id: "add_use_case",
                  color: "primary",
                  name: "Add Use Case",
                  icon: "mdi-plus",
                }
              : undefined,
          ],
        },
        {
          id: "pricing",
          tag: "pricing",
          name: "Pricing",
          icon: "mdi-currency-usd",
          children: [
            ...(value.plans && value.plans.length
              ? value.plans.map((availablePlan) => ({
                  id: `plan_${availablePlan.id}`,
                  tag: "pricing",
                  name: availablePlan.name,
                  icon: "mdi-currency-usd",
                }))
              : []),
            this.editable
              ? {
                  id: "add_plan",
                  color: "primary",
                  name: "Add Plan",
                  icon: "mdi-plus",
                }
              : undefined,
          ],
        },
      ];
    },
  },
};
</script>


<style lang="scss" scoped>
.value-navigation {
  max-height: calc(100vh - 200px);
  overflow: auto;
  margin: 10px;

  transition: all 0.4s;

  .navigation-element-icon {
    font-size: 18px;
    line-height: 36px;
  }

  .navigation-element-name {
    text-transform: uppercase;
  }
}
</style>