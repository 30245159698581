<template>
  <!-- <v-parallax
    class="how-it-works-slider-bg"
    :src="service &&  service.type &&  service.type.image ? service.type.image: '/static/images/backgrounds/69.webp'"
    
    height="350"
  > -->

  <div
    class="how-it-works-slider-bg"
    :style="{
      backgroundImage: `url(${
        service && service.type && service.type.image
          ? service.type.image
          : '/static/images/backgrounds/69.webp'
      })`,
    }"
  >
    <v-carousel
      class="how-it-works-slider dark-alpha-04"
      hide-delimiter-background
      show-arrows-on-hover
      :cycle="autoSpin"
      height="450"
    >
      <template v-slot:prev="{ on, attrs }">
        <div class="direction-wrapper-light">
          <v-icon class="direction-icon" color="black" v-bind="attrs" v-on="on"
            >mdi-chevron-left</v-icon
          >
        </div>
      </template>
      <template v-slot:next="{ on, attrs }">
        <div class="direction-wrapper-light">
          <v-icon class="direction-icon" color="black" v-bind="attrs" v-on="on"
            >mdi-chevron-right</v-icon
          >
        </div>
      </template>
      <v-carousel-item height="100%" v-for="(step, i) in steps" :key="i">
        <v-sheet height="100%" class="transparent">
          <v-card class="how-it-works-slider-card transparent" dark flat>
            <v-card-text class="how-it-works-slider-content">
              <v-col
                cols="12"
                lg="8"
                sm="12"
                xs="12"
                class="how-it-works-slider-content-slider "
              >
                <v-icon size="40" class="pa-6" v-if="step.icon">
                  {{ step.icon }}
                </v-icon>
                <h1 class="how-it-works-tabs-header center">
                  {{ step.header }}
                </h1>
                <h3
                  v-if="step.sub_header"
                  class="how-it-works-tabs-subheader mt-5 mb-5 center"
                >
                  {{ step.sub_header }}
                </h3>
                <v-divider width="100%" class="mt-5 mb-5"></v-divider>
                <p class="step-content simple-text">
                  {{ step.content }}
                </p>
              </v-col>
            </v-card-text>
          </v-card>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
  </div>
  <!-- </v-parallax> -->
</template>



<script>
export default {
  props: {
    steps: {
      type: Array,
      default: () => [],
    },
    autoSpin: {
      type: Boolean,
      default: false,
    },
    service: {
      default: () => ({}),
    },
  },
  data: () => ({
    tab: null,
  }),
};
</script>

   

<style lang="scss" scoped>
.how-it-works-slider-bg {
  background-size: cover;

  @media (max-width: 1264px) {
  }
  .how-it-works-slider {
    flex-direction: column;
    display: flex;
    justify-items: center;
    justify-content: center;

    .how-it-works-slider-card {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .how-it-works-slider-subheader {
        margin-bottom: 20px;
      }

      .how-it-works-tabs-header {
        text-transform: uppercase;
        font-weight: 200;

        &.center {
          text-align: center;
        }
      }

      .how-it-works-tabs-subheader {
        &.center {
          text-align: center;
        }
      }

      .how-it-works-slider-content {
        align-items: center;
        display: flex;
        flex-direction: column;

        &.center {
          text-align: center;
        }

        min-width: 200px;
        max-width: 100%;
        flex-grow: 1;
        .how-it-works-slider-content-slider {
          min-width: 200px;
          max-width: 100%;
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
    }
  }
  .step-content {
    text-align: center;
  }
}
</style>
