
<template>
  <v-card color="use-case-card transparent elevation-0 " class="">
    <v-card-title class="use-case-card-title">
      <v-icon color="primary" class="use-case-title-icon">{{
        value.icon || "icon-lightbulb"
      }}</v-icon>

      <h2 class="use-case-title">{{ value.name }}</h2>
    </v-card-title>

    <v-card-text>
      <p class="simple-text use-case-text">
        {{ value.description }}
      </p>
    </v-card-text>
  </v-card>
</template>


<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  computed: {},
  components: {},
  methods: {},
};
</script>

<style lang="scss">
.use-case-card {
  .use-case-card-title {
    display: flex;
    flex-direction: column;

    .use-case-title-icon {
      font-size: 48px;
      margin-bottom: 20px;
    }
    .use-case-title {
      font-size: 14px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 0.4em;
      text-align: center;
      word-break: break-word;
    }
  }
  .use-case-text {
    text-align: justify;
    text-align: center;

  }
}
</style>